import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { superUserCreate } from "../../../../controller/Register/Register";
import style from "./Create.module.css";
import logoSm from "../../../../assets/register/logoSm.svg";
import createImg from "../../../../assets/register/createImg.svg";
import iconOperator from "../../../../assets/register/iconOperator.svg";
import messages from "../../../../assets/register/messages.svg";
import loginBg from "../../../../assets/register/myRegister/loginBg.svg";
import logotype from "../../../../assets/register/myRegister/smartPay.svg";
import footerEmail from "../../../../assets/register/myRegister/smallemail.svg";
import call from "../../../../assets/register/myRegister/call.svg";
import {useTranslation} from "react-i18next";

function Create() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const loading = useSelector(({ register }) => register.loading);
  const onFinish = (e) => {
    e.preventDefault();
    let body = {
      firstname: e.nativeEvent.srcElement[0].value,
      lastname: e.nativeEvent.srcElement[1].value,
      email: e.nativeEvent.srcElement[2].value,
      password: e.nativeEvent.srcElement[3].value,
    };
    dispatch(superUserCreate({ body, navigate }));
  };

  return (
    // <div className={style.container}>
    //   <div>
    //     <div className={style.logoImg}>
    //       <img src={logoSm} alt="" />
    //     </div>
    //     <div className={style.imgCreate}>
    //       <img width={400} src={createImg} alt="" />
    //     </div>
    //   </div>
    //   <div className={style.create}>
    //     <div>
    //       <div className={style.create__title}>Создать аккаунт</div>
    //       <div className={style.create__title__text}>Введите свои данные ниже, чтобы продолжить</div>
    //       <hr className={style.divider} />
    //       <Form
    //         name="basic"
    //         preserve={false}
    //         layout="vertical"
    //         hideRequiredMark
    //         onFinish={onFinish}
    //         onFinishFailed={onFinishFailed}
    //         autoComplete="off"
    //       >
    //         <Row gutter={[16, 8]}>
    //           <Col span={12}>
    //             <Form.Item
    //               name="firstname"
    //               label="Имя"
    //               rules={[{ required: true, message: "Поле не должно быть пустым!" }]}
    //               className={style.inputs}
    //             >
    //               <Input />
    //             </Form.Item>
    //           </Col>
    //           <Col span={12}>
    //             <Form.Item
    //               name="lastname"
    //               label="Фамилия"
    //               rules={[{ required: true, message: "Поле не должно быть пустым!" }]}
    //               className={style.inputs}
    //             >
    //               <Input />
    //             </Form.Item>
    //           </Col>
    //           <Col span={24}>
    //             <Form.Item
    //               name="email"
    //               label="Электронная почта"
    //               className={style.input__gmail}
    //               rules={[
    //                 {
    //                   type: "email",
    //                   required: true,
    //                   message: "Поле не должно быть пустым!",
    //                 },
    //               ]}
    //             >
    //               <Input />
    //             </Form.Item>
    //           </Col>
    //           <br />
    //           <Col span={24}>
    //             <Form.Item
    //               name="password"
    //               label="Новый пароль"
    //               className={style.input__gmail}
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Поле не должно быть пустым!",
    //                 },
    //               ]}
    //             >
    //               <Input.Password />
    //             </Form.Item>
    //           </Col>
    //           <Col span={24} className={style.create__text}>
    //             <p>
    //               Нажимая кнопку регистарция, вы принимаете
    //               <span className={style.create__text__color}>
    //                 {" "}
    //                 Условия, Политику использования данных и Политку в отношении файлов cookie{" "}
    //               </span>
    //               .Вы можете получать от нас уведомления на электронную почту , отказаться от которых можно в любой
    //               моммент.
    //             </p>
    //           </Col>
    //           <Col span={24} className={style.footer_regis}>
    //             <Form.Item>
    //               <Button type="primary" htmlType="submit" loading={loading} className={style.register}>
    //                 Регистарция
    //               </Button>
    //             </Form.Item>
    //           </Col>
    //         </Row>
    //       </Form>
    //       <div className={style.links}>
    //         <div className={style.links__div}>
    //           <div>
    //             <img className={style.imgLink} src={iconOperator} alt="" />
    //           </div>
    //           <div>
    //             <a href="#1">+992301100100</a>
    //           </div>
    //         </div>
    //         <div className={style.links__div}>
    //           <div>
    //             <img className={style.imgLink} src={messages} alt="" />
    //           </div>
    //           <div>
    //             {" "}
    //             <a href="#1">support@smartpay.tj</a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="bg-[#F4F7F9] h-screen">
      <div className="h-screen overflow-auto">
        <img
          className="h-[4rem] my-10 md:ml-3 lg:ml-7 xl:ml-7 sm:w-3/5 md:w-auto lg:w-auto xl:w-1/2"
          src="https://smartpay.tj/assets/img/smartpay_logo_color.svg"
        />
        <div className="sm:hidden lg:block xl:block">
          <img
            className="z-20 md:ml-7 lg:ml-8 xl:ml-16 absolute w-1/3 bottom-1/5"
            src={loginBg}
            alt="login"
          />
        </div>
      </div>
      <div className="flex flex-col bg-white justify-between md:w-1/2 lg:w-2/5 xl:w-2/5 h-screen overflow-auto z-50 absolute sm:top-32 md:top-0 md:right-0 lg:right-8 xl:right-28">
        <div>
          <div className="sm:px-5 md:px-10 xl:px-20 py-10 lg:mt-9 xl:mt-14 flex flex-col space-y-14">
            <div>
              <h2 className="text-2xl mb-1 text-center text-[#3B8098]">
                {t('CREATE_ACCOUNT')}
              </h2>
              <p className="text-sm text-center m-0 pb-2 border-b-2 border-[#C4C4C4] text-[#6A6A6A]">
                {t('ENTER_NOTE')}
              </p>
            </div>

            <form className="flex flex-col space-y-4" onSubmit={onFinish}>
              <div className="flex sm:flex-col xl:flex-row w-full sm:space-x-0 xl:space-x-3 sm:space-y-4 xl:space-y-0 sm:items-start xl:items-center">
                <div className="flex flex-col sm:basis-full xl:basis-1/2 space-y-1">
                  <p className="text-xs text-[#6A6A6A] m-0 pl-2">{t('FIRST_NAME')}</p>
                  <input
                    className="border border-[#C4C4C4] py-1 px-3 rounded-xl focus:outline-none"
                    name="firstname"
                  />
                </div>
                <div className="flex flex-col sm:basis-full xl:basis-1/2 space-y-1">
                  <p className="text-xs text-[#6A6A6A] m-0 pl-2">{t('LAST_NAME')}</p>
                  <input
                    className="py-1 px-3 border border-[#C4C4C4] rounded-xl focus:outline-none"
                    name="lastname"
                  />
                </div>
              </div>
              <div className="w-full flex flex-col space-y-1">
                <p className="text-xs text-[#6A6A6A] m-0 pl-2">
                  {t('EMAIL')}
                </p>
                <input
                  type="email"
                  className="w-full py-1 px-3 border border-[#C4C4C4] rounded-xl focus:outline-none"
                  name="email"
                  placeholder
                />
              </div>
              <div className="w-full flex flex-col space-y-1">
                <p className="text-xs text-[#6A6A6A] m-0 pl-2">{t('PASSWORD')}</p>
                <input
                  className="w-full py-1 px-3 border border-[#C4C4C4] rounded-xl focus:outline-none"
                  type="password"
                  name="password"
                />
              </div>
              <div className="w-full">
                <p className="text-xs text-[#6A6A6A] m-0 pl-2">
                  {t('CREATE_ACCOUNT_NOTE')}
                </p>
              </div>
              <div className="py-5 w-full flex flex-row justify-center">
                <button
                  type="submit"
                  loading={loading}
                  className="w-3/5 text-white text-lg text-center rounded-full py-2 bg-[#116072]"
                >
                  {t('REGISTER')}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="flex flex-col pb-3 space-y-2">
          <div className="flex flex-row justify-center space-x-3 items-center pr-6">
            <img src={call} alt="call" />
            <a className="text-base mb-1 text-[#6A6A6A]" type="number">
            {t('SMARTPAY_PHONE')}
            </a>
          </div>
          <div className="flex flex-row justify-center space-x-3 items-center">
            <img src={footerEmail} alt="email" />
            <a className="text-base mb-1 text-[#6A6A6A]" type="email">
              {t('SMARTPAY_EMAIL')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Create;
