import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { superUserVerify } from "../../../../controller/Register/Register";
import loginBg from "../../../../assets/register/myRegister/loginBg.svg";
import logotype from "../../../../assets/register/myRegister/smartPay.svg";
import footerEmail from "../../../../assets/register/myRegister/smallemail.svg";
import call from "../../../../assets/register/myRegister/call.svg";
import {useTranslation} from "react-i18next";
function Verify() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector(({ register }) => register.loading);
  const onFinish = (e) => {
    e.preventDefault();
    dispatch(
      superUserVerify({
        body: {
          email: localStorage.getItem("email"),
          pincode: e.nativeEvent.srcElement[0].value,
        },
        navigate,
      })
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    // <div style={{ margin: "50px auto", width: 450 }}>
    //   <h1 style={{ textAlign: "center" }}>Введите код верификации</h1>
    //   <p style={{ textAlign: "center" }}>
    //     На Вашу электронную почту был оправлен код подтверждения, введите его в
    //     поле ниже что бы продолжить
    //   </p>
    //   <Divider />
    //   <Form
    //     name="basic"
    //     preserve={false}
    //     layout="vertical"
    //     hideRequiredMark
    //     onFinish={onFinish}
    //     onFinishFailed={onFinishFailed}
    //     autoComplete="off"
    //   >
    //     <Row gutter={[16, 8]} style={{ marginBottom: 60 }}>
    //       <Col span={24}>
    //         <Form.Item
    //           name="pincode"
    //           label="Введите код верификации"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Поле не должно быть пустым!",
    //             },
    //           ]}
    //         >
    //           <Input />
    //         </Form.Item>
    //       </Col>
    //       <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
    //         <Form.Item>
    //           <Button
    //             type="primary"
    //             htmlType="submit"
    //             style={{
    //               backgroundColor: "#3B8098",
    //               borderRadius: "5px",
    //               height: "50px",
    //               color: "#FFFFFF",
    //               fontSize: "22px",
    //               fontFamily: "regular",
    //               lineHeight: "26.4px",
    //               padding: "12px 74px",
    //               border: "none",
    //               boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    //             }}
    //             loading={loading}
    //           >
    //             Потвердеть
    //           </Button>
    //         </Form.Item>
    //       </Col>
    //       <Col span={24}>
    //         <p>Не получили код подтверждения? </p>
    //       </Col>
    //       <Col span={24}>
    //         <Button type="link">Отправить повторно</Button>
    //       </Col>
    //     </Row>
    //   </Form>
    // </div>

    // --------------------------

    <div className="bg-[#F4F7F9] h-screen">
      <div className="h-screen overflow-auto">
        <img
          className="h-[4rem] my-10 md:ml-3 lg:ml-7 xl:ml-7 sm:w-3/5 md:w-auto lg:w-auto xl:w-1/2"
          src="https://smartpay.tj/assets/img/smartpay_logo_color.svg"
          alt="logotype"
        />
        <div className="sm:hidden lg:block xl:block">
          <img
            className="z-20 md:ml-7 lg:ml-8 xl:ml-16 absolute w-1/3 bottom-1/5"
            src={loginBg}
            alt="login"
          />
        </div>
      </div>
      <div className="flex flex-col bg-white justify-between md:w-1/2 lg:w-2/5 xl:w-2/5 h-screen overflow-auto z-50 absolute sm:top-32 md:top-0 md:right-0 lg:right-8 xl:right-28">
        <div>
          <div className="sm:px-5 md:px-10 xl:px-20 py-14 lg:mt-9 xl:mt-14 flex flex-col space-y-14">
            <div>
              <h2 className="text-2xl mb-1 text-center text-[#3B8098]">
                {t('VERIFICATION_ENTER')}
              </h2>
              <p className="text-sm text-center m-0 pb-2 border-b-2 border-[#C4C4C4] text-[#6A6A6A]">
              {t('VERIFICATION_NOTE')}
              </p>
            </div>

            <form className="flex flex-col space-y-4" onSubmit={onFinish}>
              <div className="w-full flex flex-col space-y-1">
                <p className="text-xs text-[#6A6A6A] m-0 pl-2">
                {t('VERIFICATION_ENTER')}
                </p>
                <input
                  className="w-full py-1 px-3 border border-[#C4C4C4] rounded-xl focus:outline-none"
                  name="pincode"
                />
              </div>
              <div className="py-5 w-full flex flex-row justify-center">
                <button
                  type="submit"
                  onLoad={loading}
                  className="w-3/5 text-white text-lg text-center rounded-full py-2 bg-[#116072]"
                >
                  {t('APPLY')}
                </button>
              </div>
              <div className="flex flex-col space-y-3">
                <a className="text-[#6A6A6A] text-xs text-center m-0">
                {t('CODE_NOT_RECEIVED')}
                </a>
                <a className="text-base text-center m-0 text-[#3B8098]">
                {t('RESEND')}
                </a>
              </div>
            </form>
          </div>
        </div>

        <div className="flex flex-col pb-3 space-y-2">
          <div className="flex flex-row justify-center space-x-3 items-center pr-6">
            <img src={call} alt="call" />
            <a className="text-base mb-1 text-[#6A6A6A]" type="number">
            {t('SMARTPAY_PHONE')}
            </a>
          </div>
          <div className="flex flex-row justify-center space-x-3 items-center">
            <img src={footerEmail} alt="email" />
            <a className="text-base mb-1 text-[#6A6A6A]" type="email">
              {t('SMARTPAY_EMAIL')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify;
