import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosRequest } from "../../utils/axiosRequest";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async ({ id, archive }, { rejectWithValue }) => {
    try {
      let uri = `users/`;
      if (id !== undefined) {
        uri += `?q=${id}`;
      }

      if (archive === true) {
        uri += `?archive=${archive}`;
      }

      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);
export const getUser = createAsyncThunk(
  "users/getUser",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `users/me`;

      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getMyAvatar = createAsyncThunk(
  "users/getUserAvatar",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `users/avatar/my`;

      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchUserAvatar = createAsyncThunk(
  "users/patchUserAvatar",
  async ({ form, id }, { rejectWithValue }) => {
    try {
      let uri = `users/avatar/${id}`;

      const { data } = await axiosRequest.patch(uri, form, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getUserAvatar = createAsyncThunk(
  "users/getUserAvatar",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `users/avatar/${id}`;

      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchMyAvatar = createAsyncThunk(
  "users/patchMyAvatar",
  async (form, { rejectWithValue }) => {
    try {
      let uri = `users/avatar/my`;

      const { data } = await axiosRequest.patch(uri, form, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchUser = createAsyncThunk(
  "users/patchUser",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `users/me`;

      const { data } = await axiosRequest.patch(uri, body);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getSities = createAsyncThunk(
  "services/getSities",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get("common/tj_cities");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postUsersCreate = createAsyncThunk(
  "users/postUsersCreate",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.post(`users/create`, body);

      dispatch(getUsers());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchUsersUpdate = createAsyncThunk(
  "users/patchUsersUpdate",
  async (body, { rejectWithValue, dispatch, getState }) => {
    const id = getState().users.id;
    try {
      const { data } = await axiosRequest.patch(`users/${id}`, body);

      dispatch(getUsers());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchUsersStatus = createAsyncThunk(
  "users/patchUsersStatus",
  async (body, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await axiosRequest.patch(`users/status`, body);

      dispatch(getUsers());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  console.log(action?.payload?.error);
};

const setLoading = (state) => {
  state.loading = true;
};

const initialUser = {
  firstname: "",
  lastname: "",
  username: "",
  phonenumber: "",
  address: "",
  city_id: "",
  role_id: null,
  disabled: false,
};
const slice = createSlice({
  name: "users",
  initialState: {
    users: [],
    cities: [],
    user: initialUser,
    me: {},
    avatar: "",
    createModal: false,
    updateModal: false,
    loading: false,
    id: null,
  },
  reducers: {
    handleModalOpenAndClose: (state, action) => {
      const { name, value, id = null } = action.payload;
      state[name] = value;
      state.user = initialUser;
      if (id) {
        state.id = id;
        state.user = state.users.find((elem) => elem.id === id);
      }
    },
  },
  extraReducers: {
    [getUsers.pending]: setLoading,
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    [getUsers.rejected]: setError,
    [getSities.pending]: setLoading,
    [getSities.fulfilled]: (state, action) => {
      state.loading = false;
      state.cities = action.payload;
    },
    [getSities.rejected]: setError,
    [postUsersCreate.pending]: setLoading,
    [postUsersCreate.fulfilled]: (state, action) => {
      state.loading = false;
      state.createModal = false;
      notification["success"]({
        message: `${action.payload.id}`,
        description: "Новый сотрудник успешно создан!",
      });
    },
    [postUsersCreate.rejected]: setError,
    [patchUsersUpdate.pending]: setLoading,
    [patchUsersUpdate.fulfilled]: (state, action) => {
      state.loading = false;
      state.createModal = false;
      notification["success"]({
        message: `${action.payload.firstname}`,
        description: "Маъулумоти корманд иваз шуд!",
      });
    },
    [patchUsersUpdate.rejected]: setError,
    [patchUsersStatus.pending]: setLoading,
    [patchUsersStatus.fulfilled]: (state, action) => {
      state.loading = false;
      notification["success"]({
        message: `Тахрири корманд`,
        description: "Статуси корманд иваз шуд!",
      });
    },
    [patchUsersStatus.rejected]: setError,
    [getUser.pending]: setLoading,
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.me = action.payload || {};
    },
    [getUser.rejected]: setError,
    [getUserAvatar.pending]: setLoading,
    [getUserAvatar.fulfilled]: (state, action) => {
      state.loading = false;
      state.avatar = action.payload;
    },
    [getUserAvatar.rejected]: setError,
    [getMyAvatar.pending]: setLoading,
    [getMyAvatar.fulfilled]: (state, action) => {
      state.loading = false;
      state.avatar = action.payload;
    },
    [getMyAvatar.rejected]: setError,
    [patchUser.pending]: setLoading,
    [patchUser.fulfilled]: (state, action) => {
      state.loading = false;
      notification["success"]({
        message: `${action.payload.firstname}`,
        description: "Маъулумоти корманд иваз шуд!",
      });
    },
    [patchUser.rejected]: setError,
  },
});

export const { handleModalOpenAndClose } = slice.actions;

export default slice.reducer;
