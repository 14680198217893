import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosRequest } from "../../utils/axiosRequest";

export const getRecipients = createAsyncThunk(
  "recipients/getRecipients",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `beneficiaries/`;
      if (id !== undefined) {
        uri += `?q=${id}`;
      }
      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postRecipientsCreate = createAsyncThunk(
  "recipients/postRecipientsCreate",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.post(`beneficiaries`, body);

      dispatch(getRecipients());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchRecipientsUpdate = createAsyncThunk(
  "recipients/patchRecipientsUpdate",
  async (body, { rejectWithValue, dispatch, getState }) => {
    const id = getState().recipients.id;
    try {
      const { data } = await axiosRequest.patch(`beneficiaries/${id}`, body);

      dispatch(getRecipients());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchRecipientsDisabled = createAsyncThunk(
  "recipients/patchRecipientsUpdateDisabled",
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await axiosRequest.patch(
        `beneficiaries/disable?beneficiary_id=${parseInt(id)}`
      );

      dispatch(getRecipients());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getArea = createAsyncThunk(
  "recipients/getArea",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(`common/tj_area`);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  state.authStatus = action.payload.error;
};

const setLoading = (state) => {
  state.loading = true;
};

const initialRecipient = {
  recipient: "",
  recipient_tin: "",
  bank_id: 0,
  sett_account: "",
  area_id: 0,
  services: [],
};
const slice = createSlice({
  name: "recipients",
  initialState: {
    recipients: [],
    area: [],
    recipient: initialRecipient,
    createModal: false,
    updateModal: false,
    deleteModal: false,
    loading: false,
    id: null,
  },
  reducers: {
    handleModalOpenAndClose: (state, action) => {
      const { name, value, id = null } = action.payload;
      state[name] = value;
      state.recipient = initialRecipient;
      if (id) {
        state.id = id;
        let recipient = state.recipients.find((elem) => elem.id === id);
        state.recipient.recipient = recipient.recipient?.tj;
        state.recipient.recipient_tin = recipient.recipient_tin;
        state.recipient.bank_id = recipient.bank_id;
        state.recipient.sett_account = recipient.sett_account;
        state.recipient.area_id = recipient.area_id;
        state.recipient.services = recipient.services;
      }
    },
    handleChange: (state, action) => {
      const { name, value } = action.payload;
      state.recipient[name] = value;
    },
  },
  extraReducers: {
    [getRecipients.pending]: setLoading,
    [getRecipients.fulfilled]: (state, action) => {
      state.loading = false;
      state.recipients = action.payload;
    },
    [getRecipients.rejected]: setError,

    [postRecipientsCreate.pending]: setLoading,
    [postRecipientsCreate.fulfilled]: (state, action) => {
      state.loading = false;
      state.createModal = false;
      notification["success"]({
        message: `${action?.payload?.name || ""}`,
        description: "Новый получатель успешно создан!",
      });
    },
    [postRecipientsCreate.rejected]: setError,
    [patchRecipientsUpdate.pending]: setLoading,
    [patchRecipientsUpdate.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateModal = false;

      notification["success"]({
        message: `${action?.payload?.name || ""}`,
        description: "Получатель успешно изменен!",
      });
    },
    [patchRecipientsUpdate.rejected]: setError,
    [getArea.pending]: setLoading,
    [getArea.fulfilled]: (state, action) => {
      state.loading = false;
      state.area = action.payload;
    },
    [getArea.rejected]: setError,
  },
});

export const { handleModalOpenAndClose, handleChange } = slice.actions;

export default slice.reducer;
