import { lazy } from "react";

// global routes
export const Layout = lazy(() => import("../layout/SmartPayLayout"));
export const Login = lazy(() => import("../model/Login/Login"));

// administrator and supervisor  routes
export const Panel = lazy(() => import("../view/Panel/Panel"));
export const Invoices = lazy(() => import("../model/Invoices/Invoices"));
export const InvoiceForms = lazy(() => import("../view/Invoices/Forms"));
export const InvoiceForm = lazy(() => import("../view/Invoices/Form"));
export const Invoice = lazy(() => import("../view/Invoices/Invoice"));
export const Profile = lazy(() => import("../view/Profile/Profile"));

export const Applications = lazy(() =>
  import("../view/Applications/Applications")
);
export const Application = lazy(() =>
  import("../view/Applications/Application")
);
export const Decor = lazy(() => import("../view/Applications/Decor"));

export const Users = lazy(() => import("../model/Users/Users"));
export const Services = lazy(() => import("../model/Services/Services"));
export const Forms = lazy(() => import("../model/Forms/Forms"));
export const FormsCreate = lazy(() => import("../model/Forms/Create"));
export const FormsUpdate = lazy(() => import("../model/Forms/Update"));
export const Recipients = lazy(() => import("../model/Recipients/Recipients"));

// supervisor
export const ProfileOrg = lazy(() => import("../model/ProfileOrg/ProfileOrg"));
export const Register = lazy(() => import("../view/Register/Register"));

// register routes
export const RegisterForBusiness = lazy(() =>
  import("../view/Register/RegisterForBusiness")
);
export const RegisterForGovernment = lazy(() =>
  import("../view/Register/RegisterForGovernment")
);
export const RegisterForIndividuals = lazy(() =>
  import("../view/Register/RegisterForIndividuals")
);
export const Registration = lazy(() =>
  import("../view/Registration/Registration")
);
export const RegistrationSecStep = lazy(() =>
  import("../view/Registration/RegistrationSecStep")
);
export const RegistrationLastStep = lazy(() =>
  import("../view/Registration/RegistrationLastStep")
);
