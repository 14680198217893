import "antd/dist/antd.css";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import Spinner from "./components/Spinner/Spinner";
import Login from "./model/Login/Login";
import {
  Application,
  Applications,
  Decor,
  Forms,
  FormsCreate,
  FormsUpdate,
  Invoice,
  InvoiceForm,
  InvoiceForms,
  Invoices,
  Layout,
  Panel,
  Profile,
  ProfileOrg,
  Recipients,
  Register,
  RegisterForBusiness,
  RegisterForGovernment,
  RegisterForIndividuals,
  Registration,
  RegistrationLastStep,
  RegistrationSecStep,
  Services,
  Users,
} from "./routes/routes";
import { getToken } from "./utils/axiosRequest";
import { RequireAuth } from "./utils/RequreAuth";
import Account from "./view/Register/Account/Account";
import Create from "./view/Register/Account/Create/Create";
import Verify from "./view/Register/Account/Verify/Verify";

function App() {
  // sessionStorage.setItem("isLogged", false);
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();

  let operatorRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId/invoice",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Invoice />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "applications",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Applications />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Application />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: "decor",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Decor />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let administratorRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Panel />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId/invoice",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Invoice />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "applications",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Applications />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Application />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: "decor",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Decor />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "users",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Users />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "services",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Services />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Forms />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_create",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsCreate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_update/:id",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsUpdate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_update/:id",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsUpdate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "recipients",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Recipients />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profileOrg",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <ProfileOrg />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let supervisorRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Panel />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "invoices",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId/invoice",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Invoice />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "applications",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Applications />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Application />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: "decor",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <Decor />
                  </RequireAuth>
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "users",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Users />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "services",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Services />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Forms />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_create",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsCreate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "forms_update/:id",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <FormsUpdate />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "recipients",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Recipients />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profileOrg",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <ProfileOrg />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let supervisorDisableRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Registration />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          index: false,
          path: "regSecondStep",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <RegistrationSecStep />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          index: false,
          path: "regLastStep",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <RegistrationLastStep />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];
  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("isLogged"))) {
      setIsLogged(JSON.parse(sessionStorage.getItem("isLogged")));
    }
  }, [isLogged, navigate]);

  let renderOperatorRoutes = useRoutes(operatorRoutes);
  let renderAdministratorRoutes = useRoutes(administratorRoutes);
  let renderSupervisorRoutes = useRoutes(supervisorRoutes);
  let renderSupervisorDisableRoutes = useRoutes(supervisorDisableRoutes);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/register"
          element={
            <Suspense fallback={<Spinner />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path="registerForIndividuals"
          element={
            <Suspense fallback={<Spinner />}>
              <RegisterForIndividuals />
            </Suspense>
          }
        />
        <Route
          path="registerForGovernment"
          element={
            <Suspense fallback={<Spinner />}>
              <RegisterForGovernment />
            </Suspense>
          }
        />
        <Route
          path="registerForBusiness"
          element={
            <Suspense fallback={<Spinner />}>
              <RegisterForBusiness />
            </Suspense>
          }
        />
        <Route path="/account" element={<Account />}>
          <Route index element={<Create />} />
          <Route path="verify" element={<Verify />} />
        </Route>
      </Routes>
      {getToken()?.role_id === 3 && renderOperatorRoutes}
      {getToken()?.role_id === 2 && renderAdministratorRoutes}
      {getToken()?.role_id === 1 &&
        getToken().disable === false &&
        renderSupervisorRoutes}
      {getToken()?.role_id === 1 &&
        getToken().disable &&
        renderSupervisorDisableRoutes}
    </>
  );
}

export default App;
